let app = null;  
let open: Function|null = null; 

export async function createFeedbackService(element: HTMLElement){
    var feedbackApp = await import('scope.libraries.vue.components.FeedbackApp2.vue').then(m => m.default);

    const { SingletonApp } = await import('o365-vue-services');
    if(app == null){
        app = SingletonApp.getOrCreateApp({
            id: "o365-navbar-feedback-app",
            appComponent: feedbackApp,
            provides: {
                'set-on-open': (pOpenFn: Function) => { open = pOpenFn}
            },
            appendTo: element
        })
        app.mount();
    } else if (open) {
        open();
    }
} 
